<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina Titlu="Balanta" @on_add_clicked="show_dialog()" :AdaugaVisible="false" />
            </div>
          </template>
          <div class="filtre">
            <el-form ref="my-form" @submit.prevent="save" label-position="top" :rules="rules"  size="mini" :model="Filters">
              <el-row :gutter="20">
                <el-col style="width:20%">
                  <el-form-item label="Gestiune" prop="IdGestiune">
                    <el-select class="full-width" v-model="Filters.IdGestiune" filterable>
                      <el-option v-for="item in Info.Gestiuni" :key="item.Id" :label="item.Nume" :value="item.Id" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col style="width: 30%">
                  <el-form-item class="full-width" label="Interval">
                    <el-date-picker class="full-width" v-model="Filters.Data" type="daterange" range-separator="-"
                      start-placeholder="Data inceput" end-placeholder="Data sfarsit"
                      :picker-options="datePickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col style="width: 20%">
                  <el-form-item label="Produs">
                    <el-input v-model="Filters.Produs"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-col style="width:100%">
                <div class="footer-form">
                  <el-button type="primary" native-type="submit" @click="refresh_info()">Aplica</el-button>
                  <el-button type="danger" @click="reset()">Reseteaza</el-button>
                  <el-button type="info" native-type="submit" @click="print()">Printeaza</el-button>
                </div>
              </el-col>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <!-- <br> -->
   
    <el-card v-if="typeof Result.balanta != 'undefined' && Result.balanta.length > 0" 
      style="margin: 5px 0px 5px 0px">
      <table 
        
        cellpadding="5" class="tabel-data" 
        style="margin-top: 10px; width: 100%; color:white ">
        <thead class="gri">
          <tr>
            <th style="text-align: left;" width="350px">Produs</th>
            <th style="text-align: left;">Categorie produs</th>
            <th style="text-align: left;">UM</th>
            <th style="text-align: right;">Pret mediu final</th>
            <th style="text-align: right;">Stoc initial</th>
            <th style="text-align: right;">Intrari</th>
            <th style="text-align: right;">Iesiri</th>
            <th style="text-align: right;">Diferente inventar</th>
            <th style="text-align: right;">Stoc final</th>
            <th style="text-align: right;">Sold initial</th>
            <th style="text-align: right;">Valoare intrari</th>
            <th style="text-align: right;">Valoare iesiri</th>
            <th style="text-align: right;">Valoare diferente inventar</th>
            <th style="text-align: right;">Sold final</th>
          </tr>
        </thead>
        <tbody class="gri-deschis">
          <tr v-for="(item, idx) in Result.balanta" :key="'rand-balanta-' + idx" 
          style="border-bottom: 1px solid #EBEEF5 !important;" >
            <td style="text-align: left;">{{item.NumeProdus}}</td>
            <td style="text-align: left;">{{item.NumeCategorie}}</td>
            <td>{{ item.NumeUM }}</td>
            <td style="text-align: right;">{{item.PretMediuFinal}}</td>
            <td style="text-align: right;">{{item.StocInitial}}</td>
            <td style="text-align: right;">{{item.TotalIntrari}}</td>
            <td style="text-align: right;">{{item.TotalIesiri}}</td>
            <td style="text-align: right;">-</td>
            <td style="text-align: right;">{{item.StocFinal}}</td>
            <td style="text-align: right;">{{item.SoldInitial | format_money}}</td>
            <td style="text-align: right;">{{item.TotalValoareIntrari | format_money}}</td>
            <td style="text-align: right;">{{item.totalValoareIesiri | format_money}}</td>
            <td style="text-align: right;">-</td>
            <td style="text-align: right;">{{item.SoldFinal | format_money}}</td>
          </tr>
        </tbody>
          <tr style="border-bottom: 1px solid #EBEEF5 !important; font-weight:bold" >
            <td colspan="5">TOTAL</td>
            <td style="text-align: right;">{{ Result.SumeTabel.cantInt}}</td>
            <td style="text-align: right;">{{ Result.SumeTabel.cantIes}}</td>
            <td colspan="3"></td>
            <td style="text-align: right;">{{ Result.SumeTabel.valInt | format_money}}</td>
            <td style="text-align: right;">{{ Result.SumeTabel.valIes | format_money}}</td>
            <td ></td>
            <td style="text-align: right;">{{ Result.SumeTabel.totalSoldFin | format_money}}</td>
          </tr>
        
      </table>
    </el-card>  

  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from "@/widgets/TitluPagina";
import moment from 'moment';

export default {
  name: 'Balanta',
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      Filters: {
        IdGestiune: '',
        Data: [moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')],
        Produs: '',
      },
      Info: {
        Gestiuni: [],
      },
      rules: {
        IdGestiune: [{ required: true, message: "Campul este obligatoriu" }],
      },
      Result: [],
      datePickerOptions: { firstDayOfWeek: 1 },
    }
  },
  methods: {
    async get_info() {
      var response = await this.post("Rapoarte/balanta/get_info_balanta");
      this.Info.Gestiuni = response.gestiuni;
    },

    async refresh_info() {

      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          var response = await this.post("Rapoarte/balanta/index", { Filters: this.Filters });
          this.Result = response;
        } // else console.log("eroare validare formular!");
      });



      
    },

    reset() {
      this.Filters = {
        IdGestiune: '',
        Data: [moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')],
        Produs: '',
      }
    },

    async print() {
      let res = await this.post('Rapoarte/Balanta/print', { Filters: this.Filters, Produse: this.Result.balanta });
      let doc = window.open("", "_blank");
      doc.document.write(res.html);
    }

  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  }
}
</script>

<style lang="less" scoped>
.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);

  .el-col {
    width: 20%;
  }

  .el-input-number {
    width : 100% !important;
  }

  .tabel-data {
    width : 300px !important;
    word-break: keep-all;
    font-size: small;
    th {
      font-size: large;
    }
    
  }

  .tabel-data thead tr th {
    border: 1px solid black;
  }

}
</style>